const colors = {
  backgroundColor: '#1B1B1E',
  fadeColor: '#373F51',
  calmColor: '#A9BCD0',
  blueColor: '#58A4B0',
  redColor: '#B06358',
  purpleColor: '#6358b0',
  greenColor: '#a4b058',
  primaryColor: '#D8DBE2'
}

export default colors;
