import React, { Component } from 'react';
import '../../Static/styles.css'

class ContactInfo extends Component {

  render(){
    return (
      <div className='CatagoryContent'>
        <div className='CatagoryTitle'>
          Contact Info
        </div>
        <div className='CatagoryText'>
          Phone - (515)577-6095
          <br/>
          Email - Levirclark@gmail.com
          <br/>
          Address - 8275 NW 54th Ave.Johnston, IA 50131
        </div>
      </div>
    )
  }

}

export default ContactInfo;
