import React, { Component } from 'react';
import '../../Static/styles.css'

class Education extends Component {

  render(){
    return (
      <div className='CatagoryContent'>
        <div className='CatagoryTitle'>
          Education
        </div>
        <div className='CatagoryText'>
          BS in Computer Science
          <br/>
          Iowa State University
          <br/>
          August 2015 - May 2019
          <br/>
          GPA - 3.50
        </div>
        <div className='CatagoryText'>
          Most Proficient Technologies
          <br/>
          - Java
          <br/>
          - Javascript - React
          <br/>
          - HTML & CSS
          <br/>
          - Git
          <br/>
          - Jenkins
          <br/>
          - SQL
        </div>
        <div className='CatagoryText'>
          Minor Experience with the Following Technologies
          <br/>
          - C#
          <br/>
          - Prolog
          <br/>
          - Java Database Connectivity
          <br/>
          - Sql Server Management Studio
          <br/>
          - Entity Framework
        </div>
      </div>
    )
  }

}

export default Education;
