import React, { Component } from 'react';
import '../../Static/styles.css'
import DropDown from '../Units/DropDown';
class Experience extends Component {

  constructor(props){
    super(props);

    this.state = {
      activeSections: []
    }
  }

  openSection(sectionID){
    if(this.state.activeSections.includes(sectionID)){
      this.setState({
        activeSections: this.state.activeSections.filter(possibleSectionID => possibleSectionID !== sectionID)
      });
      return;
    }
    this.setState({
      activeSections: [...this.state.activeSections, sectionID]
    })
  }

  render(){
    return (
      <div className='CatagoryContent'>
        <div className='CatagoryTitle'>
          Experience
        </div>

        <div className='CatagoryText'>
          <div>
            Web Developer, Center for Survey Statistics and Methodology
            <br/>
            <br/>
            August 2018 - Present
                  <br/>
                  <br/>
                  - Assisted in the production of two web applications
                  <br/>
                  - Applications facilitated the collection of land survey data
                  <br/>
                  - Applications had lengthy lists of data interdependancies
                  <br/>
                  - Applications had lengthy lists of user types and privaleges
                  <br/>
                  - Applications had lengthy lists of confidentiality requirements
                  <br/>
                  - Prioritized work based on time requirements
                  <br/>
                  - A healthy dosage of challenge and a great team made these projects very enjoyable
          </div>
        </div>

        <div className='CatagoryText'>
          <div>
            Software Development Intern, Berkley Technology Services
                <br/>
                <br/>
                June 2018 - August 2018
                <br/>
                <br/>
                - Introduced to a Microsoft based shop
                <br/>
                - Worked on an enterprise content management team
                <br/>
                - Learned C#
                <br/>
                - Introduced to SQL
                <br/>
                - Introduced to IBM's FileNet
                <br/>
                - Built web API for communication from IBM FileNet applications
                <br/>
                - Built basic database test generation program
                <br/>
                - Built self-decrypting program
              </div>
        </div>

        <div className='CatagoryText'>
          <div>
            Web Developer, Iowa State University
            <br/>
            <br/>
            April 2017 - May 2018
                <br/>
                <br/>
                - Introduced to the wonderful world of clean code
                <br/>
                - Helped with transition from the Blackboard LMS to Canvas LMS
                <br/>
                - Learned good unit testing practices
                <br/>
                - Began application development for CSSM (Javascript-React)
          </div>
        </div>
      </div>
    )}
}

export default Experience;
