import React, {Component} from 'react';
import '../../Static/styles.css';

class CircleButton extends Component{

  render(){
    let {size, color, customSyles, label, callback} = this.props;
    return (
      <span className={this.props.isActive ? 'circleButtonExploded' : 'circleButton'}
       onClick={() => callback()}
       style={{width: this.props.isActive ? '100vw' : size,
          height: this.props.isActive ? '100vh' :  size,
           backgroundColor: color,
            ...customSyles}}>
        <span className='circleButtonSpan' style={{width: size, height: size}}>
          {!this.props.isActive && label}
        </span>
      </span>
    )
  }
}

export default CircleButton;
