import React, {Component} from 'react';
import '../../Static/styles.css';

class DropDown extends Component{

  render(){


    let display = this.props.isOpen ? '' : 'none';
    let arrow = this.props.isOpen ? '↑' : '↓';

    return (
      <div onClick={() => this.props.onActivate(this.props.sectionID)} className='dropDown'>
        <div className='dropDownTitle'>
          {this.props.title}
          <br/>
          {arrow}{arrow}{arrow}
        </div>
        <div className='dropDownContent' style={{display}}>
          <br/>
          {this.props.content}
        </div>
      </div>
    )
  }

}

export default DropDown;
