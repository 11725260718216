import React, { Component } from 'react';
import CircleButton from './Units/CircleButton';
import colors from '../Static/colors';
import '../Static/styles.css';
import AboutMe from './Catagories/AboutMe';
import Experience from './Catagories/Experience';
import Education from './Catagories/Education';
import ContactInfo from './Catagories/ContactInfo';

const catagoryObjects = [
  {catagory: 'About Me', color: colors.redColor, component: AboutMe},
  {catagory: 'Experience', color: colors.purpleColor, component: Experience},
  {catagory: 'Education', color: colors.greenColor, component: Education},
  {catagory: 'Contact Info', color: colors.blueColor, component: ContactInfo}
];

//z-index is set to 0, as edge uses z index transitionally
const defaultGlobalTransition = 'all 500ms, z-index 0ms';

class Main extends Component {
  constructor(props){
    super(props);

    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let totalButtons = catagoryObjects.length + 1;

    this.state = {
      backgroundColor: colors.backgroundColor,
      mainButtonClicked: false,
      windowWidth,
      windowHeight,
      globalTransition: defaultGlobalTransition,
      activeScreen: '',
      buttonSize: this.getButtonSize(window.innerWidth, window.innerHeight, totalButtons),
      totalButtons,
      hideLabel: ''
    }
    this.reinstateAnimation = this.reinstateAnimation.bind(this);
    this.showLabels = this.showLabels.bind(this);
  }

  showLabels(){
    this.setState({hideLabel: ''});
  }

  getButtonSize(windowWidth, windowHeight, totalButtons){
    let maxFactor = windowWidth > windowHeight ? windowWidth : windowHeight;
    return maxFactor / (totalButtons + 1);
  }

  reinstateAnimation(){
    this.setState({globalTransition: defaultGlobalTransition});
  }

  componentDidMount(){
    window.addEventListener("resize", () => {
        clearTimeout(this.reinstateAnimation);
        setTimeout(this.reinstateAnimation, 100);
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight, buttonSize: this.getButtonSize(window.innerWidth, window.innerHeight, this.state.totalButtons), globalTransition: ''});
      });
  }

  getMarginForMiddleVeritcal(){
    return ((this.state.windowHeight - this.state.buttonSize) / 2) + 'px';
  }

  getMarginForMiddleHorizontal(){
    return ((this.state.windowWidth - this.state.buttonSize) / 2) + 'px';
  }

  calculateCustomMargins(buttonMargins, customAttribute, limitingSize, buffer){
    for(let i = 0; i < this.state.totalButtons; i++){
      let marginTop = '';
      let marginLeft = '';
      if(!this.state.mainButtonClicked){
        marginTop = this.getMarginForMiddleVeritcal();
        marginLeft = this.getMarginForMiddleHorizontal();
        buttonMargins.push({marginTop, marginLeft});
        continue;
      }
      let newLocation = {marginTop: '', marginLeft: ''};
      newLocation[customAttribute] = ((i + 1) * buffer) + (i * this.state.buttonSize);
      if(!newLocation.marginTop){
        newLocation.marginTop = this.getMarginForMiddleVeritcal();
      }
      if(!newLocation.marginLeft){
        newLocation.marginLeft = this.getMarginForMiddleHorizontal();
      }
      buttonMargins.push(newLocation);
    }
  }

  //Hefty bit of work, calculates the proper spacing for the buttons
  getButtonCustomMargins(){
    let buttonMargins = [];
    let customAttribute = this.state.windowWidth > this.state.windowHeight ? 'marginLeft' : 'marginTop';
    let limitingSize = this.state.windowWidth > this.state.windowHeight ? this.state.windowWidth : this.state.windowHeight;
    let buffer = (limitingSize - (this.state.totalButtons * this.state.buttonSize)) / (this.state.totalButtons + 1);
    this.calculateCustomMargins(buttonMargins, customAttribute, limitingSize, buffer);
    return buttonMargins;
  }

  render() {
    let buttonMargins = this.getButtonCustomMargins();
    return (
      <div className='Main' style={{backgroundColor: this.state.backgroundColor, transition: this.state.globalTransition}}>

        {catagoryObjects.map((catagoryObject, index) => {
          let catagoryIsActive = this.state.activeScreen === catagoryObject.catagory;
          let catagoryScreenStyles = {backgroundColor: catagoryObject.color, marginLeft: catagoryIsActive ? '' : '100vw'}
          if(!catagoryIsActive){
            catagoryScreenStyles.transition = 'none';
          }
          let customButtonStyles = {};
          if(!catagoryIsActive){
            customButtonStyles = buttonMargins[index + 1];
          }
          if(this.state.hideLabel === catagoryObject.catagory){
            customButtonStyles.zIndex = '1';
          }
          return [
              <CircleButton
               color={catagoryObject.color}
               label={this.state.hideLabel !== catagoryObject.catagory ? catagoryObject.catagory : ''}
               size={this.state.buttonSize}
               callback={() => !this.state.activeScreen && this.state.hideLabel !== catagoryObject.catagory && this.setState({activeScreen: catagoryObject.catagory, hideLabel: catagoryObject.catagory})}
               customSyles={customButtonStyles}
               isActive={catagoryIsActive}/>,

             <div className={'Catagory'}
               style={catagoryScreenStyles}>
               <catagoryObject.component buttonSize={this.state.buttonSize} color={catagoryObject.color}/>
             </div>
          ]
        }).reverse()}

        <CircleButton
         color={colors.blueColor}
         label={'LRC'}
         size={this.state.buttonSize}
         callback={() => !this.state.activeScreen && this.setState({mainButtonClicked: !this.state.mainButtonClicked})}
         customSyles={{...buttonMargins[0], fontSize: '5vmax'}}/>

        <CircleButton
         color={colors.backgroundColor}
         label={'<'}
         size={'40px'}
         callback={() =>{
           this.setState({activeScreen: ''});
           setTimeout(this.showLabels, 500);
         }}
         customSyles={{fontSize: '28px',
            marginLeft: this.state.activeScreen ? '' : '100vw',
            transition: this.state.activeScreen ? 'all 500ms' : 'none',
            transitionDelay: this.state.activeScreen ? '500ms' : '0ms',
            zIndex: '2'}}/>
      </div>
    );
  }
}

export default Main;
