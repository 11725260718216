import React, { Component } from 'react';
import {aboutMeParagraph} from '../../Static/content';
import '../../Static/styles.css'
import CircleButton from '../Units/CircleButton';

class AboutMe extends Component {

  render(){
    return (
      <div className='CatagoryContent'>
        <div className='CatagoryTitle'>
          About Me
        </div>
        <div className='CatagoryText'>
          {aboutMeParagraph}
        </div>
      </div>
    )
  }

}

export default AboutMe;
